import React from 'react'

import s from './container.module.css'


export default ({ children, belowHeader }) => (
  <div className={[s.container, belowHeader ? s.belowHeader : ''].filter(x => !!x).join(' ')}>
    {children}
  </div>
)
