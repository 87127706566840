import React from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import PageHeader from '../components/pageHeader'
import Container from '../components/container'
import Footer from '../components/footer';

class RootIndex extends React.Component {

  render() {

    return (
      <Layout location={this.props.location} logoLink="/">
        <div style={{ background: '#EBEBEB' }}>
          <Helmet title="Vekominn" />
          <Container belowHeader>
            <br />
            <br />
            <br />
            <PageHeader big center>Notify</PageHeader>


          </Container>
          <Footer />
        </div>
      </Layout>
    )
  }
}

export default RootIndex
